<template>
  <v-container>
    <v-overlay v-if="loading" style="z-index: 9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="tab == 3">
      <v-form ref="form" v-model="formValid" class="mb-4">
        <v-row>
          <v-col>
            <v-text-field
              outlined
              id="input-1"
              v-model="form.fName"
              type="text"
              placeholder="First Name"
              required
              :rules="firstnameRules"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              v-model="form.lName"
              placeholder="Last Name"
              required
              :rules="lastnameRules"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          label="Date of Birth"
          v-model="form.dob"
          outlined
          required
          type="date"
          placeholder="Date of Birth"
          :rules="dobRules"
        ></v-text-field>

        <v-text-field
          outlined
          v-model="pincode"
          required
          type="number"
          placeholder="Pincode"
          :rules="pincodeRules"
        ></v-text-field>

        <div v-if="pincodeData" class="pb-5">
          <div class="success--text my-2">
            <i class="far fa-check-circle"></i> Service available at
            {{ pincodeData.state_name }}, {{ pincodeData.city_name }}.
          </div>
        </div>
        <div v-else-if="!pincodeData && pincode.length > 5" class="my-2">
          <div class="error--text">
            <i class="far fa-times-circle"></i> Service unavailable at this
            pincode.
          </div>
        </div>

        <div class="mb-3 text--disabled" v-if="!pincodeData">
          To choose a vehicle, please select the pincode.
        </div>
        <v-autocomplete
          :disabled="!pincodeData"
          label="Choose Vehicle"
          outlined
          item-value="modelName"
          item-text="modelName"
          :items="availableBikes"
          v-model="form.vehicle"
          required
          :rules="vehicleRules"
        >
        </v-autocomplete>

        <v-text-field
          outlined
          v-model="mobile"
          required
          type="number"
          label="Phone Number"
          placeholder="Enter Phone Number"
          :disabled="loading || sendingOTP"
          :loading="loading || sendingOTP"
          :rules="mobileRules"
        ></v-text-field>
        <b-spinner v-if="sendingOTP || loading"></b-spinner>
        <div v-if="otpSent">
          <div v-if="!mobileVerified">Enter OTP</div>
          <v-text-field
            v-if="!mobileVerified"
            outlined
            v-model="otp"
            required
            type="number"
            :disabled="sendingOTP || mobileVerified"
          ></v-text-field>
          <div class="success--text pt-2" v-if="mobileVerified">
            OTP Verified <i class="fal fa-check-circle"></i>
          </div>
          <div
            class="error--text pt-2"
            v-else-if="!mobileVerified && otpSent && otpSentVerifiedOnceFailed"
          >
            OTP Verification Failed
          </div>
        </div>

        <v-checkbox
          v-model="checked"
          label="I authorize Ontrack and it's representatives to reachout to me
                via either call/SMS/Whatsapp in regards to availing the loan
                facility from Bajaj Auto Finance Ltd or services of
                Ontrack."
          required
        />

        <div class="text-center">
          <v-btn @click="onSubmit" large color="primary">Apply Now</v-btn>
        </div>
        <!-- <b-button @click="openForm" type="reset" variant="danger">Reset</b-button> -->
      </v-form>
    </div>

    <div v-if="tab == 4">
      <v-container class="text-center">
        <v-fab-transition>
          <h1 class="success--text">
            <i class="far fa-check-circle"></i>
          </h1>
        </v-fab-transition>
        <h2><strong>Form Submitted</strong></h2>
        <div>
          Thank you for submitting the details. Someone from Bajaj Auto Finance
          will reach out to you shortly.
        </div>

        <v-btn class="mx-auto my-5" color="primary" @click="closeModal"
          >Explore More</v-btn
        >
      </v-container>
    </div>

    <div v-if="tab == 5">
      <v-container class="text-center">
        <v-fab-transition>
          <h1 class="success--text">
            <i class="far fa-check-circle"></i>
          </h1>
        </v-fab-transition>
        <h2><strong>Form Already Submitted</strong></h2>
        <div>
          You have already applied for loan. Someone from Bajaj Auto Finance
          will reach out to you shortly.
        </div>

        <v-btn class="mx-auto my-5" color="primary" @click="closeModal"
          >Explore More</v-btn
        >
      </v-container>
    </div>
  </v-container>
</template>

<script>
import pincodes from "../store/jsons/pincodes";
import makeModel from "../store/jsons/makeModel";
import bikes from "../store/jsons/bike.json";
import axios from "axios";
// import { eventBus } from "@/main";
export default {
  data() {
    return {
      formValid: "",
      state: "KARNATAKA",
      pincodes: pincodes,
      makeModel: makeModel,
      bikes: bikes,
      form: {
        fName: "",
        lName: "",
        mobile: "",
        vehicle: null,
        source: this.utmSource,
        campaign: this.campaign,
        dob: "",
      },
      checked: [],
      show: true,
      otp: null,
      showOTPModal: false,
      otpSent: false,
      tab: 3,
      mobile: "",
      mobileVerified: false,
      pincode: "",
      loading: false,
      pincodeData: null,
      otpSentVerifiedOnceFailed: false,
      sendingOTP: false,
      firstnameRules: [(v) => !!v || "First name is required"],
      lastnameRules: [(v) => !!v || "Last name is required"],
      dobRules: [(v) => !!v || "Date of birth is required"],
      pincodeRules: [(v) => !!v || "Pincode is required"],
      vehicleRules: [(v) => !!v || "Vehicle is required"],
      mobileRules: [(v) => !!v || "Mobile number is required"],
    };
  },
  props: {
    utmSource: String,
    campaign: String,
    closeModal: Function,
  },
  mounted() {},
  watch: {
    mobile(newValue) {
      console.log(newValue);
      this.mobileVerified = false;
      this.otpSentVerifiedOnceFailed = false;

      if (newValue.length == 10) {
        this.sendOtp();
      }
    },
    otp(newValue) {
      console.log(newValue);
      if (newValue.length == 4) {
        this.verifyOTP();
      }
    },
    pincode(newValue) {
      console.log(newValue);
      if (newValue.length == 6) {
        const data = this.pincodes.find((x) => {
          return x.pincode == this.pincode;
        });
        if ((this.pincodeData = data)) {
          this.pincodeData = data;
          this.state = this.pincodeData.state_name;
        } else {
          this.pincodeData = null;
        }
      } else {
        this.pincodeData = null;
      }
    },
  },
  computed: {
    availableBikes() {
      return bikes.filter((x) => {
        return x.state.toUpperCase() == this.state.toUpperCase();
      });
    },
  },
  methods: {
    redirect() {
      this.$router.push("/bajaj-finance");
    },
    onSubmit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.sendToBajaj();
      }
    },
    sendToBajaj() {
      this.loading = true;
      axios
        .post("https://glacier.on-track.in/api/finance/create/lead", {
          fName: this.form.fName,
          lName: this.form.lName,
          mobile: this.mobile,
          vehicle: this.form.vehicle,
          source: this.utmSource,
          campaign: this.campaign,
          pincode: this.pincodeData,
          dob: this.form.dob,
        })
        .then((resp) => {
          if (resp.data.error == 1) {
            this.loading = false;
            var eventName = this.utmSource
              ? "bajajFailed-" + this.utmSource
              : "bajajFailed-organic";
            window.fbq("track", eventName);
            this.$gtag.event(eventName, {
              event_category: "click-event",
              event_label: "page view",
              value: 1,
            });
            this.tab = 5;
            return this.$toast.error(resp.data.message, { position: "top" });
          } else {
            this.loading = false;
            this.closeModal();
            this.tab = 4;
            eventName = this.utmSource
              ? "bajajSuccess-" + this.utmSource
              : "bajajSuccess-organic";

            this.$gtag.event(eventName, {
              event_category: "click-event",
              event_label: "page view",
              value: 1,
            });
            window.fbq("track", eventName);
          }

          // congratulations message
        });
    },
    async sendOtp() {
      this.sendingOTP = true;
      try {
        this.loading = true;
        this.otpSentVerifiedOnceFailed = false;
        await axios
          .post("https://glacier.on-track.in/api/profile/v2/user/try", {
            mobile: this.mobile,
          })
          .then(() => {
            this.otpSent = true;
            this.$toast.success("OTP Sent", {
              position: "top",
            });
            this.loading = false;
            // congratulations message
          });
      } catch {
        this.sendingOTP = false;
        this.$toast.error("Cannot Sent OTP", {
          position: "top",
        });
        this.loading = false;
      } finally {
        this.loading = false;
        this.sendingOTP = false;
      }
    },
    verifyOTP() {
      this.loading = true;
      axios
        .post("https://glacier.on-track.in/api/profile/v1/user/verify/otp", {
          mobile: this.mobile,
          otp: this.otp,
        })
        .then(() => {
          this.mobileVerified = true;
          this.loading = false;
          this.$toast.success("OTP Verified", {
            position: "top",
          });
          // congratulations message
        })
        .catch(() => {
          this.mobileVerified = false;
          this.otpSentVerifiedOnceFailed = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
